import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox, Switch } from "antd";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { BreadCrum } from "../../common";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { handleKeyPressSpace } from "../../common/CommonFunctions";
import { BrandChangeModal } from "../../components/BrandChangeModal/BrandChangeModal";
import { useDispatch, useSelector } from "react-redux";
import { addProductFeatureAction, addProductFeaturesHandler } from "../../redux/action/addProductFeaturesSlice";
import Loader from "../../components/Loder/loader";
import { getProductFeatureContentHandler } from "../../redux/action/getProductFeatureContentSlice";
import { v4 as uuidv4 } from 'uuid';
import { validationSchemaAddClaim, validationSchemaSpanishAddClaim } from "../../common/Validations";

const Addclaims = () => {
  const navigate = useNavigate();
  const [manageSwitch, setManageSwitch] = useState(false);
  const [handleCheckBox, setHandleCheckBox] = useState("");
  const [isOn, setIsOn] = useState(false);
  const [claimStatusModal, setClaimStatusModal] = useState(false)
  const [manageClaimStatus, setClaimStatus] = useState("")
  const [redirectionUrlEnglish, setRedirectionUrlEnglish] = useState([])
  const [changeClaimStatus, setChangeClaimStatus] = useState("")

  const { state } = useLocation();
  const dispatch = useDispatch()

  const getProductId = localStorage.getItem("productId")
  const refFeatureId = localStorage.getItem("refFeatureId")

  const addProductSelector = useSelector((state) => state?.addProductFeatures)
  const getProductFeatureContent = useSelector((state) => state?.getProductFeatureContentMain)

  const handleModalYes = () => {
    setClaimStatusModal(true)
    setIsOn(!isOn)
    if (state?.claimType === "url") {
      const existingClaims = redirectionUrlEnglish?.map((item) => {
        return {
          id: item?.id,
          language: item?.language,
          urlData: item?.urlData,
          claimName: item?.claimName,
          claimType: item?.claimType,
          claimStatus: item?.claimStatus,
          formData: item?.formData,
          formDescription: item?.formDescription,
          formTitle: item?.formTitle,
          quizData: item?.quizData,
        }
      }) || [];


      const newClaim = {
        id: state?.itemStored?.id,
        language: state?.language,
        urlData: state?.handleSwitch === false ? state?.itemStored?.urlData : state?.itemStored?.urlData,
        claimName: state?.claimName || state?.claimNameSpanish || state?.claimName || state?.claimNameSpanish,
        claimType: state?.claimType || state?.claimTypeSpanish || state?.claimType || state?.claimTypeSpanish,
        claimStatus: isOn === true ? "INACTIVE" : "ACTIVE",
        formData: [],
        quizData: [],
        formDescription: "",
        formTitle: "",
      };

      const matchSeqId = redirectionUrlEnglish.findIndex((item) => item?.id === state?.id)
      if (matchSeqId > -1) {
        existingClaims[matchSeqId] = newClaim;
      } else {
        existingClaims.push(newClaim);
      }
      const payload = {
        productId: Number(getProductId),
        featureId: Number(refFeatureId),
        content: {
          type: "claim",
          claim: existingClaims
        }
      }
      setClaimStatusModal(false)
      dispatch(addProductFeaturesHandler(payload));
    }else if(state?.claimType==="offlineClaim"){
      const existingClaims = redirectionUrlEnglish?.map((item) => {
        return {
          id: item?.id,
          language: item?.language,
          urlData: item?.urlData,
          claimName: item?.claimName,
          claimType: item?.claimType,
          claimStatus: item?.claimStatus,
          formData: item?.formData,
          formDescription: item?.formDescription,
          formTitle: item?.formTitle,
          quizData: item?.quizData,
        }
      }) || [];

      const newClaim = {
        id: state?.itemStored?.id,
        language: state?.language,
        urlData: state?.handleSwitch === false ? state?.itemStored?.urlData : state?.itemStored?.urlData,
        claimName: state?.claimName || state?.claimNameSpanish || state?.claimName || state?.claimNameSpanish,
        claimType: state?.claimType || state?.claimTypeSpanish || state?.claimType || state?.claimTypeSpanish,
        claimStatus: isOn === true ? "INACTIVE" : "ACTIVE",
        formData: [],
        quizData: [],
        formDescription: "",
        formTitle: "",
      };

      const matchSeqId = redirectionUrlEnglish.findIndex((item) => item?.id === state?.id)
      if (matchSeqId > -1) {
        existingClaims[matchSeqId] = newClaim;
      } else {
        existingClaims.push(newClaim);
      }

      const payload = {
        productId: Number(getProductId),
        featureId: Number(refFeatureId),
        content: {
          type: "claim",
          claim: existingClaims
        }
      }
      setClaimStatusModal(false)
      dispatch(addProductFeaturesHandler(payload));
      setTimeout(() => {
        navigate("/claim")
      }, 2000);
    } 
    else if (state?.claimType === "form") {
      const existingClaims =
        redirectionUrlEnglish?.map((item) => {
          return {
            formData: item?.formData?.map((item, i) => {
              return {
                questionType: item?.questionType,
                questionText: item?.questionText,
                answerChoice: item?.answerChoice,
                questionInfo: '',
                userAnswer: '',
                numberOfChoice: Number(item?.numberOfChoice),
                correctAnswer: item?.correctAnswer,
              };
            }),
            id: item?.id,
            language: item?.language,
            claimName: item?.claimName,
            claimType: item?.claimType,
            claimStatus: item?.claimStatus,
            setQuestionsCount: item?.formData?.length,
            setAttemptedQuestionsCount: 0,
            formTitle: item?.formTitle,
            formDescription: item?.formDescription,
            urlData: item?.urlData,
            quizData: item?.quizData,
          };
        }) || [];
      // New Claim form Addes functionality
      const newClaim = {
        formData: state?.itemStored?.formData.map((item, i) => {
          return {
            questionType: item?.questionType,
            questionText: item?.questionText,
            answerChoice: item?.answerChoice,
            questionInfo: '',
            userAnswer: '',
            numberOfChoice: Number(item?.numberOfChoice),
            correctAnswer: item?.correctAnswer,
          };
        }),
        id: state?.itemStored?.id,
        language: state?.language,
        claimName: state?.claimName || state?.claimNameSpanish || state?.claimName || state?.claimNameSpanish,
        claimType: state?.claimType || state?.claimTypeSpanish || state?.claimType || state?.claimTypeSpanish,
        claimStatus: isOn === true ? "INACTIVE" : "ACTIVE",
        setQuestionsCount: state?.itemStored?.formData?.length,
        setAttemptedQuestionsCount: 0,
        formTitle: state?.itemStored?.formTitle,
        formDescription: state?.itemStored?.formDescription,
        urlData: '',
        quizData: [],
      };
      const matchSeqId = redirectionUrlEnglish.findIndex((item) => item?.id === state?.id)
      if (matchSeqId > -1) {
        existingClaims[matchSeqId] = newClaim;
      } else {
        existingClaims.push(newClaim);
      }

      const payload = {
        productId: Number(getProductId),
        featureId: Number(refFeatureId),
        content: {
          type: "claim",
          claim: existingClaims
        }
      }
      setClaimStatusModal(false)
      dispatch(addProductFeaturesHandler(payload));
    } else if (state?.claimType === "quiz") {
      const existingClaims = redirectionUrlEnglish?.map((item) => {
        return {
          quizData: item?.quizData?.map((quizData) => {
            return {
              quizSet: quizData?.quizSet?.map((data) => {
                return {
                  setQuestions: data?.setQuestions.map((quesData) => {
                    return {
                      answerChoice: quesData?.answerChoice,
                      correctAnswer: quesData?.correctAnswer,
                      numberOfChoice: quesData?.numberOfChoice,
                      questionInfo: quesData?.questionInfo,
                      questionText: quesData?.questionText,
                      questionType: quesData?.questionType,
                      setIndex: quesData?.setIndex,
                      userAnswer: quesData?.userAnswer,
                    };
                  }),
                  setName: data?.setName,
                  setDescription: data?.setDescription,
                  setStatus: data?.setStatus,
                };
              }),
              quizName: quizData?.quizName,
              quizDescription: quizData?.quizDescription,
              quizStatus: "pending"
            };
          }),
          id: item?.id,
          language: item?.language,
          claimName: item?.claimName,
          claimType: item?.claimType,
          claimStatus: item?.claimStatus,
          setQuestionsCount: item?.formData?.length,
          setAttemptedQuestionsCount: 0,
          formTitle: item?.formTitle,
          formDescription: item?.formDescription,
          urlData: item?.urlData,
          formData: item?.formData,
          quizName: item?.quizName,
          quizDescription: item?.quizDescription,
        };
      }) || [];
      const currentState = (
        state?.itemStored?.quizData
          ?.flatMap(item => item?.quizSet ?? [])
      );
      const groupedSets = [];
      currentState?.forEach((acc, index) => {
        const setName = acc?.setName;
        const description = acc?.setDescription;
        let Object = {};
        if (setName?.length && description?.length) {
          Object = {
            setName: setName,
            setDescription: description,
            setStatus: "pending",
            setQuestions: [],
          };
          const findSetWiseQuestion = acc?.setQuestions.filter(
            (item) => item?.setIndex === index
          );
          findSetWiseQuestion.forEach((question) => {
            Object.setQuestions.push({
              userAnswer: "",
              answerChoice: question?.answerChoice,
              questionInfo: "",
              setIndex: question?.setIndex,
              questionText: question?.questionText,
              questionType: question?.questionType,
              numberOfChoice: Number(question?.numberOfChoice),
              correctAnswer: question?.correctAnswer,
            });
          });
        }
        groupedSets?.push(Object);
      });
      const newClaim = {
        quizData: [
          {
            quizName: state?.itemStored?.quizData[0]?.quizName,
            quizStatus: "pending",
            quizDescription: state?.itemStored?.quizData[0]?.quizDescription,
            quizSet: groupedSets, // Multiple sets added here
          },
        ],
        claimName: state?.claimName || state?.claimNameSpanish || state?.claimName || state?.claimNameSpanish,
        claimType: state?.claimType || state?.claimTypeSpanish || state?.claimType || state?.claimTypeSpanish,
        claimStatus: isOn === true ? "INACTIVE" : "ACTIVE",
        language: state?.language,
        id: state?.id,
        urlData: "",
        formData: []
      };
      const matchSeqId = redirectionUrlEnglish?.findIndex(
        (item) => item?.id === (state?.claimData?.id || state?.id)
      );
      if (matchSeqId !== -1) {
        existingClaims[matchSeqId] = newClaim; // Update existing claim
      } else {
        existingClaims.push(newClaim); // Add new claim
      }
      const payload = {
        productId: Number(getProductId),
        featureId: Number(refFeatureId),
        content: {
          type: "claim",
          claim: existingClaims
        }
      }
      setClaimStatusModal(false)
      dispatch(addProductFeaturesHandler(payload));
    }
  }

  useEffect(() => {
    const productFeatures = getProductFeatureContent?.data?.data?.content?.claim;
    setRedirectionUrlEnglish(productFeatures)
  }, [getProductFeatureContent])

  useEffect(() => {
    let payload = {
      productId: Number(getProductId),
      refFeatureId: Number(refFeatureId)
    }
    dispatch(getProductFeatureContentHandler(payload))
    dispatch(addProductFeatureAction.addProductFeaturesInfoReset())
  }, [getProductId, refFeatureId, addProductSelector])

  useEffect(() => {
    for (let index = 0; index < getProductFeatureContent?.data?.data?.content?.claim?.length; index++) {
      const element = getProductFeatureContent?.data?.data?.content?.claim?.find((item) => item?.id === state?.id)
      if (state?.claimType === "url" || state?.claimType === "form" || state?.claimType === "quiz" || state?.claimType === "offlineClaim") {
        setClaimStatus(element)
      } else {
        setClaimStatus("")
      }
      if (element?.claimStatus === "ACTIVE") {
        setIsOn(true)
      } else {
        setIsOn(false)
      }
    }
  }, [getProductFeatureContent, state])

  const handleCancel = () => {
    setClaimStatusModal(false)
  }

  const handleFormSubmit = (values) => {
    if (handleCheckBox === 'url' || state?.claimType === "url") {
      navigate('/redirection-url', {
        state: { handleSwitch: manageSwitch, values: values, claimData: state, claimStatus: isOn },
      });
    } else if (handleCheckBox === "quiz") {
      navigate("/quiz", {
        state: { handleSwitch: manageSwitch, values: values, claimData: state },
      });
    } else if (handleCheckBox === "form") {
      navigate("/claim-form", {
        state: { handleSwitch: manageSwitch, values: values, claimData: state },
      });
    } else if (state?.claimType === "form") {
      navigate("/edit-claim-form", {
        state: { handleSwitch: manageSwitch, values: values, claimData: state, claimStatus: isOn },
      });
    } else if (state?.claimType === "quiz") {
      navigate("/edit-quiz-form", {
        state: { handleSwitch: manageSwitch, values: values, claimData: state },
      });
    } else if (handleCheckBox === "offlineClaim" || state?.claimType==="offlineClaim") {
      const productId = Number(getProductId);
      const featureId = Number(refFeatureId);

      const existingClaims = redirectionUrlEnglish?.map((item)=>{
        return {
          id: item?.id,
          language: item?.language,
          urlData: item?.urlData,
          claimName: item?.claimName,
          claimType: item?.claimType,
          claimStatus: item?.claimStatus,
          formData: item?.formData,
          formDescription: item?.formDescription,
          formTitle: item?.formTitle,
          quizData: item?.quizData,
          quizName: item?.quizName,
          quizDescription: item?.quizDescription,
        }
      })||[]

      // New claim added
      const newClaim = {
        id: uuidv4(),
        language: manageSwitch === false ? "en" : "es",
        urlData: "",
        claimName: values?.claimName?.trim() || values?.claimNameSpanish?.trim(),
        claimType: values?.claimType ||values?.claimTypeSpanish,
        claimStatus: "ACTIVE",
        formData: [],
        quizData: [],
        formDescription: "",
        formTitle: "",
        quizName: "",
        quizDescription: "",
      };
      const matchSeqId = redirectionUrlEnglish?.findIndex((item) => item?.id === state?.id)

      if (matchSeqId > -1) {
        existingClaims[matchSeqId] = newClaim;
      } else {
        existingClaims.push(newClaim);
      }

      const payload = {
        productId,
        featureId,
        content: {
          type: "claim",
          claim: existingClaims
        }
      };
      dispatch(addProductFeaturesHandler(payload));

      setTimeout(() => {
        navigate("/claim")
      }, 2000);
    }
  };
  
  const handleCheckboxChange = (checkboxName) => {
    setHandleCheckBox(handleCheckBox === checkboxName ? "" : checkboxName);
  };

  // Manage Claim English and spanish Switch
  useEffect(() => {
    if (state?.itemStored?.language === "en") {
      setManageSwitch(false);
    } else if (state?.itemStored?.language === "es") {
      setManageSwitch(true);
    }
  }, [state])

  const handleChange = () => {
    setClaimStatusModal(true)
    setChangeClaimStatus("manageClaimStatus")
  };

  return (
    <>
      {(addProductSelector?.isLoading === true || getProductFeatureContent?.isLoading === true) && <Loader loaderTransForm="loaderTransForm" />}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              { text: BreadCrum.PRODUCTS, onClick: () => { navigate('/dashboard'); localStorage.removeItem("productId") } },
              { text: BreadCrum.MANAGE_PRODUCTS, onClick: () => navigate("/edit-product") },
              {
                text: BreadCrum.CLAIM_LIST,
                onClick: () => navigate('/claim'),
              },
              { text: state?.claimType === "url" || state?.claimType === "quiz" || state?.claimType === "form" ? BreadCrum.MANAGE_CLAIM : BreadCrum.ADD_CLAIM, active: true },
            ]}
          />
          <div className="sectionpadding d-block mb-30">
            <Formik
              enableReinitialize
              initialValues={{
                claimName: state?.itemStored?.language === "en"
                  ? state?.itemStored?.claimName
                  : "",
                claimNameSpanish: state?.itemStored?.language === "es"
                  ? state?.itemStored?.claimName
                  : "",
                claimType: state?.claimType,
                claimTypeSpanish: state?.claimType,
              }}
              validationSchema={
                manageSwitch === false
                  ? validationSchemaAddClaim
                  : validationSchemaSpanishAddClaim
              }
              onSubmit={(values, formikBag) => {
                handleFormSubmit(values, formikBag);
              }}
            >
              {({ isSubmitting, values, setFieldValue, resetForm }) => (
                <Form className="login-form">
                  <div className="d-md-flex justify-content-between align-items-center mb-20">
                    <p className="mb-md-0 heedprodct">
                      {state?.claimType === 'url' ||
                        state?.claimType === 'quiz' ||
                        state?.claimType === 'form' || state?.claimType === 'offlineClaim'
                        ? 'Manage Claim'
                        : 'Add Claim'}
                    </p>
                    {(state?.claimType === "url" || state?.claimType === "form" || state?.claimType === "quiz" || state?.claimType ==="offlineClaim") && (
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="productname me-3  textgrey fw-bold">Claim Status</div>
                        <Switch
                          className="switches"
                          checked={isOn}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                  </div>
                  <hr />
                  <div className={manageClaimStatus?.claimStatus === "INACTIVE" ? "mb-30 disabledbtn" : "mb-30"}>
                    <div className="mb-30">
                      <label className="form-label">Select Language</label>

                      <div className="switches-container ms-0">
                        <div
                          className={`mempro ${state?.language === "es" ? "disabled" : ""}`}
                          onClick={() => {
                            if (state?.language !== "es") {
                              setManageSwitch(false);
                              setHandleCheckBox("")
                              resetForm({})
                            }
                          }}
                        >
                          English
                        </div>
                        <div
                          className={`mempro ${state?.language === "en" ? "disabled" : ""}`}
                          onClick={() => {
                            if (state?.language !== "en") {
                              setManageSwitch(true);
                              setHandleCheckBox("")
                              resetForm({})
                            }
                          }}
                        >
                          Spanish
                        </div>
                        <div
                          className={
                            !manageSwitch
                              ? 'switch-wrapper'
                              : 'switch-wrapper switchside'
                          }
                        >
                          <div className="switch">
                            {!manageSwitch ? (
                              <div>English</div>
                            ) : (
                              <div>Spanish</div>
                            )}
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className=" mb-30">
                      <label className="form-label" htmlFor="Claim Name">
                        Claim Name*
                      </label>
                      <Field
                        type="text"
                        name={
                          manageSwitch === false ? 'claimName' : 'claimNameSpanish'
                        }
                        placeholder="Enter your Claim Name"
                        className="form-control w-md-50"
                        onKeyPress={handleKeyPressSpace}
                        maxLength={50}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name={
                          manageSwitch === false ? 'claimName' : 'claimNameSpanish'
                        }
                        component="div"
                        className="error text-danger"
                      />
                    </div>

                    <p className="ptext">Select any one Claim Type*</p>
                    <div className="d-md-flex ">
                      <div className="form-check me-30 p-0">
                        <Checkbox
                          checked={handleCheckBox === 'quiz' || state?.claimType === "quiz"}
                          onChange={() => {
                            handleCheckboxChange('quiz');
                            setFieldValue(
                              manageSwitch === false
                                ? 'claimType'
                                : 'claimTypeSpanish',
                              handleCheckBox === 'quiz' ? '' : 'quiz'
                            );
                          }}
                          disabled={
                            state?.claimType === 'url' || state?.claimType === 'form' || state?.claimType === 'quiz' ||state?.claimType === 'offlineClaim'
                          }
                        >
                          Quiz
                        </Checkbox>
                      </div>
                      <div className="form-check me-30 p-0">
                        <Checkbox
                          checked={handleCheckBox === 'form' || state?.claimType === 'form'}
                          onChange={() => {
                            handleCheckboxChange('form');
                            setFieldValue(
                              manageSwitch === false
                                ? 'claimType'
                                : 'claimTypeSpanish',
                              handleCheckBox === 'form' ? '' : 'form'
                            );
                          }}
                          disabled={
                            state?.claimType === 'url' || state?.claimType === 'quiz' || state?.claimType === 'form' ||state?.claimType === 'offlineClaim'
                          }
                        >
                          Form
                        </Checkbox>
                      </div>
                      <div className="form-check me-30 p-0">
                        <Checkbox
                          checked={
                            state?.claimType === "url" || handleCheckBox === "url"
                          }
                          onChange={() => {
                            handleCheckboxChange('url');
                            setFieldValue(
                              manageSwitch === false
                                ? 'claimType'
                                : 'claimTypeSpanish',
                              handleCheckBox === 'url'
                                ? ''
                                : 'url'
                            );
                          }}
                          disabled={
                            state?.claimType === 'quiz' || state?.claimType === 'form' || state?.claimType === 'url' ||state?.claimType === 'offlineClaim'
                          }
                        >
                          Redirection URL
                        </Checkbox>
                      </div>
                      <div className="form-check p-0">
                        <Checkbox
                          checked={
                            state?.claimType === "offlineClaim" || handleCheckBox === "offlineClaim"
                          }
                          onChange={() => {
                            handleCheckboxChange('offlineClaim');
                            setFieldValue(
                              manageSwitch === false
                                ? 'claimType'
                                : 'claimTypeSpanish',
                              handleCheckBox === 'offlineClaim'
                                ? ''
                                : 'offlineClaim'
                            );
                          }}
                        disabled={
                          state?.claimType === 'quiz' || state?.claimType === 'form' || state?.claimType === 'url' ||state?.claimType === 'offlineClaim'
                        }
                        >
                          Offline Claim
                        </Checkbox>
                      </div>
                    </div>

                    <ErrorMessage
                      name={
                        manageSwitch === false ? 'claimType' : 'claimTypeSpanish'
                      }
                      component="div"
                      className="error text-danger"
                    />
                    <hr />
                    <div className="text-end">
                      <button
                        className="sbg border-0 py3 fw-18 gbo continuebtn savebtn"
                        type="submit"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div >
      {claimStatusModal && (
        <BrandChangeModal addProductFeatureModal={claimStatusModal} handleCancel={handleCancel} handleModalYes={handleModalYes} changeClaimStatus={changeClaimStatus} isOn={isOn} />
      )
      }
    </>
  );
};

export default Addclaims;
