import React, { useContext, useEffect, useState } from 'react'
import { message, Upload } from 'antd';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import uploadimg from "../../assets/uploadicon.svg";
import eye from "../../assets/eye.svg";
import deleteicon from "../../assets/deleteicon.svg";
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from "formik";
import { fileUploadAction } from '../../redux/action/fileUpload';
import { BrandChangeModal } from '../../components/BrandChangeModal/BrandChangeModal';
import { convert } from 'html-to-text';
import { useCommonMessage } from '../../common/CommonMessage';
import LanguageSwitch from '../../common/LanguageSwitch';
import { getGroupProductFeatureContentAction, getGroupProductFeatureContentHandler } from '../../redux/action/getGroupProductFeatureContent';
import { updateGroupProductFeatureAction, updateGroupProductFeaturesHandler } from '../../redux/action/updateGroupProductFeature';
import axios from 'axios';
import { API_URL } from '../../services/http';
import { BtnBold, BtnBulletList, BtnItalic, BtnNumberedList, BtnStrikeThrough, BtnUnderline, Editor, EditorProvider, HtmlButton, Toolbar } from 'react-simple-wysiwyg';
import { BtnAlignCenter, BtnAlignLeft, BtnAlignRight, BtnImageUpload, BtnSubscript, BtnSuperscript } from "../Html_Editor/BtnFontFamily";
import { ThemeContext } from '../../common/themeContext';
import { GroupDraggerPayload } from './DraggerPayload';
import Loader from '../Loder/loader';

const GroupDragger = ({ deleteUploadedFile, setDeleteUploadedFile, state }) => {
    console.log(state,"state")
    const messageApi = useCommonMessage();
    const [addProductFeatureModal, setAddProductFeatureModal] = useState(false)
    const [manageSwitch, setManageSwitch] = useState(false);
    const [fileUploaded, setFileUploaded] = useState("");
    const [spanishFileUpload, setSpanishFileUpload] = useState("")
    const [plainText, setPlainText] = useState("")
    const [plainTextSpanish, setPlaintextSpanish] = useState("")
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch()
    const fileUploadDataSelector = useSelector((state) => state?.fileUploadData)
    const updateGroupProductFeatureMainSelector = useSelector((state) => state?.updateGroupProductFeatureMain)

    const getAdminLoginId = localStorage.getItem("adminId")
    const { brandId } = useContext(ThemeContext);

    const { Dragger } = Upload;

    const getGroupProductFeatureContent = useSelector((state) => state?.getGroupProductFeatureContent)
    let getProductDataGroup = getGroupProductFeatureContent?.data?.data
    let fileUploadData = fileUploadDataSelector?.data?.data

    const resetActions = () => {
        dispatch(fileUploadAction.fileUploadInfoReset())
            dispatch(updateGroupProductFeatureAction.updateGroupProductFeatureInfoReset())
            dispatch(getGroupProductFeatureContentAction.getGroupProductFeatureContentReset())
    };

    useEffect(() => {
        if (state?.fromGroupData?.id) {
            let payload1 = {
                groupProductFeatureId: state?.fromGroupData?.id
            }
            dispatch(getGroupProductFeatureContentHandler(payload1))
        }
    }, [])

    const props = {
        name: "file",
        multiple: false,
        accept: ".pdf",
        showUploadList: false,
        async beforeUpload(file) {
            const isPDF = file.type === "application/pdf";
            if (!isPDF) {
                message.error("You can only upload PDF files!");
                setFileUploaded("")
                return false;
            }
            const isSizeAccepted = file.size / 1024 / 1024 <= 2;
            if (!isSizeAccepted) {
                message.error("File size exceeds 2MB limit!");
                setFileUploaded("")
                return false;
            }
            if (isPDF && isSizeAccepted) {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("id", getAdminLoginId)
                formData.append("brandId",brandId)
                formData.append(
                    "path",
                    `${"group/" + state?.getGroupProductFeatureSelector?.data?.data?.groupCode+ "/product/" + getGroupProductFeatureContent?.data?.data?.productCode + "/" + state?.fromGroupData?.featureName}/${manageSwitch === false?"English":"Spanish"}`
                  );
                setLoading(true)
                axios.post(API_URL + "/file/upload", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                    .then(response => {
                        dispatch(fileUploadAction.fileUploadInfoSuccess(response.data));
                        if (manageSwitch === false) {
                            setFileUploaded(file?.name);
                        } else {
                            setSpanishFileUpload(file?.name);
                        }
                    })
                    .catch(error => {
                        dispatch(fileUploadAction.fileUploadInfoFailure(error));
                    }).finally(() => {
                        setLoading(false);
                    });
                if (manageSwitch === false) {
                    setFileUploaded(file?.name);
                } else {
                    setSpanishFileUpload(file?.name)
                }
            }
            return false;
        },
    }

    const handleCancel = () => {
        setAddProductFeatureModal(false)
    }
    // Delete Modal Functionality
    const handleModalYes = () => {
        if (manageSwitch === false) {
            setAddProductFeatureModal(false)
            setFileUploaded("")
            resetActions()
            let payload = {
                id: getProductDataGroup?.id,
                content: {
                    html: false,
                    english: {
                        file: {
                            preSigned: "",
                            url: "",
                            name: "",
                            isTrue: true
                        },
                        html: {
                            isTrue: true,
                            html_content: ""
                        }
                    },
                    pdf_url: true,
                    spanish: {
                        file: {
                            url: getProductDataGroup?.content?.spanish?.file?.url || "",
                            name: getProductDataGroup?.content?.spanish?.file?.name || "",
                            isTrue: getProductDataGroup?.content?.spanish?.file?.isTrue || true,
                            preSigned: getProductDataGroup?.content?.spanish?.file?.preSigned,
                        },
                        html: {
                            isTrue: true,
                            html_content: ""
                        }
                    }
                }
            }
            dispatch(updateGroupProductFeaturesHandler(payload))
        } else if (manageSwitch === true) {
            setAddProductFeatureModal(false)
            setSpanishFileUpload("")
            resetActions()
            let payload2 = {
                id: getProductDataGroup?.id,
                content: {
                    html: false,
                    english: {
                        file: {
                            url: getProductDataGroup?.content?.english?.file?.url || "",
                            name: getProductDataGroup?.content?.english?.file?.name || "",
                            isTrue: getProductDataGroup?.content?.english?.file?.isTrue || true,
                            preSigned: getProductDataGroup?.content?.english?.file?.preSigned,
                        },
                        html: {
                            isTrue: true,
                            html_content: ""
                        }
                    },
                    pdf_url: true,
                    spanish: {
                        file: {
                            preSigned: "",
                            url: "",
                            name: "",
                            isTrue: true
                        },
                        html: {
                            isTrue: true,
                            html_content: ""
                        }
                    }
                }
            }
            dispatch(updateGroupProductFeaturesHandler(payload2))
        }
    }

    const deleteFile = () => {
        setAddProductFeatureModal(true)
        setDeleteUploadedFile("DeleteFile")
    }

    const handleFormSubmit = (values) => {
        GroupDraggerPayload({manageSwitch,getProductDataGroup,fileUploadData,values,plainText,fileUploaded,dispatch,plainTextSpanish,spanishFileUpload})
    }

    useEffect(() => {
        if (updateGroupProductFeatureMainSelector?.data?.data) {
            messageApi.open({
                type: 'success',
                content: updateGroupProductFeatureMainSelector?.data?.message,
            });
            dispatch(updateGroupProductFeatureAction.updateGroupProductFeatureInfoReset())
            let payload1 = {
                groupProductFeatureId: state?.fromGroupData?.id
            }
            dispatch(getGroupProductFeatureContentHandler(payload1))
        }
    }, [updateGroupProductFeatureMainSelector])


    console.log(getGroupProductFeatureContent,"getGroupProductFeatureContent")
    useEffect(() => {
        if (manageSwitch === false) {
            setPlainText(getProductDataGroup?.content?.english?.html?.html_content)
            setPlaintextSpanish(getProductDataGroup?.content?.spanish?.html?.html_content)
        } else if (manageSwitch === true) {
            setPlaintextSpanish(getProductDataGroup?.content?.spanish?.html?.html_content)
            setPlainText(getProductDataGroup?.content?.english?.html?.html_content)
        }
    }, [getGroupProductFeatureContent, manageSwitch]);

    const onChange = (e) => {
        setPlainText(e.target.value);
    }
    const onChangeSpanish = (e) => {
        setPlaintextSpanish(e.target.value);
    }

    return (
        <>
            {(loading === true || getGroupProductFeatureContent?.isLoading === true || getGroupProductFeatureContent?.isLoading === true || updateGroupProductFeatureMainSelector?.isLoading === true) && <Loader loaderTransForm="loaderTransForm" />}
            <LanguageSwitch manageSwitch={manageSwitch} setManageSwitch={setManageSwitch} fileUploadDataSelector={fileUploadDataSelector} />
            <Formik
                enableReinitialize
                initialValues={{
                    file_upload: "",
                    html_editor: plainText || "",
                    html_editor_spanish: plainTextSpanish || ""
                }}
                onSubmit={(values, formikBag) => {
                    handleFormSubmit(values, formikBag);
                }}
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form>
                        <div className="table-padding tabbing mb-40 btnPosition">
                            {manageSwitch === false ? (
                                <Tabs
                                    defaultActiveKey="home"
                                    id="uncontrolled-tab-example"
                                    className=""
                                >
                                    <Tab eventKey="home" title="Upload File">
                                        {(convert(plainText) !== "" && convert(plainText).trim() !== "") || convert(plainTextSpanish)?.trim() !== "" ? (
                                            <div className="removehtml">
                                                Please Remove HTML in HTML Editor
                                            </div>
                                        ) : (
                                            <div>
                                                {!(fileUploaded || getProductDataGroup?.content?.english?.file?.url) ? (
                                                    <>
                                                        <Field name="file_upload">
                                                            {({ field }) => (
                                                                <Dragger {...props}>
                                                                    <p className="ant-upload-drag-icon">
                                                                        <img src={uploadimg} alt="" />
                                                                    </p>
                                                                    <p className="ant-upload-text">
                                                                        <u>Click here</u> or drag & drop to upload .PDF file
                                                                    </p>
                                                                </Dragger>
                                                            )}
                                                        </Field>
                                                        <div className="mt-3 d-flex justify-content-between textgrey  w-100">
                                                            <div className="fs-16">Supported formats : PDF</div>
                                                            <div className="fs-16">Maximum file size : 2 MB</div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="ant-upload-list ant-upload-list-text">
                                                        <span className="ant-upload-list-item-container">
                                                            <div className="d-flex gap-2 align-items-center file-icon"><img src={uploadimg} alt="" />{fileUploaded || getProductDataGroup?.content?.english?.file?.name}</div>
                                                            <div className="d-flex gap-2">
                                                                <a href={fileUploadData || getProductDataGroup?.content?.english?.file?.url} target="_blank"><img src={eye} alt="" /></a>
                                                                <div onClick={deleteFile} className="cursorPointer">
                                                                    <img src={deleteicon} alt="" />
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {fileUploadDataSelector?.data !== null && (
                                            <div className="text-end">
                                                <button
                                                    type="submit"
                                                    className=" sbg border-0 fw-18 gbo continuebtn savebtn disclaimerBtn"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        )}
                                    </Tab>
                                    <Tab eventKey="profile" title="HTML Editor">
                                        {!(fileUploadData || getProductDataGroup?.content?.english?.file?.url || getProductDataGroup?.content?.spanish?.file?.url) ? (
                                            <>
                                                <EditorProvider>
                                                    <Editor value={plainText} onChange={onChange}>
                                                        <Toolbar>
                                                            <BtnBold />
                                                            <BtnItalic />
                                                            <BtnUnderline />
                                                            <HtmlButton />
                                                            <BtnBulletList />
                                                            <BtnNumberedList />
                                                            <BtnStrikeThrough />
                                                            <BtnAlignCenter />
                                                            <BtnAlignLeft />
                                                            <BtnAlignRight />
                                                            <BtnSuperscript />
                                                            <BtnSubscript />
                                                            <BtnImageUpload />
                                                        </Toolbar>
                                                    </Editor>
                                                </EditorProvider>
                                                <div className="mt-3 d-flex justify-content-between textgrey  w-100">
                                                    <div className="fs-16">Note: Please don't add HTML tags to your text.</div>
                                                </div>
                                                {convert(plainText).trim() !== "" && (
                                                    <div className="text-end">
                                                        <button
                                                            type="submit"
                                                            className=" sbg border-0 fw-18 gbo continuebtn savebtn disclaimerBtn"
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="removehtml">
                                                Please Remove File in uploaded file
                                            </div>
                                        )}
                                    </Tab>
                                </Tabs>
                            ) : (
                                <Tabs
                                    defaultActiveKey="home"
                                    id="uncontrolled-tab-example"
                                    className=""
                                >
                                    <Tab eventKey="home" title="Upload File">
                                        {(convert(plainTextSpanish) !== "" && convert(plainTextSpanish).trim() !== "") || convert(plainText)?.trim() !== "" && convert(plainText) ? (
                                            <div className="removehtml">
                                                Please Remove HTML in HTML Editor
                                            </div>
                                        ) : (
                                            <div>
                                                {!(spanishFileUpload || getProductDataGroup?.content?.spanish?.file?.url) ? (
                                                    <>
                                                        <Field name="file_upload">
                                                            {({ field }) => (
                                                                <Dragger {...props}>
                                                                    <p className="ant-upload-drag-icon">
                                                                        <img src={uploadimg} alt="" />
                                                                    </p>
                                                                    <p className="ant-upload-text">
                                                                        <u>Click here</u> or drag & drop to upload .PDF file spanish
                                                                    </p>
                                                                </Dragger>
                                                            )}
                                                        </Field>
                                                        <div className="mt-3 d-flex justify-content-between textgrey  w-100">
                                                            <div className="fs-16">Supported formats : PDF</div>
                                                            <div className="fs-16">Maximum file size : 2 MB</div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="ant-upload-list ant-upload-list-text">
                                                            <span className="ant-upload-list-item-container">
                                                                <div className="d-flex gap-2 align-items-center file-icon"><img src={uploadimg} alt="" />{spanishFileUpload || getProductDataGroup?.content?.spanish?.file?.name}</div>
                                                                <div className="d-flex gap-2">
                                                                    <a href={fileUploadData || getProductDataGroup?.content?.spanish?.file?.url} target="_blank"><img src={eye} alt="" /></a>
                                                                    <div onClick={deleteFile} className="cursorPointer">
                                                                        <img src={deleteicon} alt="" />
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        {fileUploadDataSelector?.data !== null && (
                                            <div className="text-end">
                                                <button
                                                    type="submit"
                                                    className=" sbg border-0 fw-18 gbo continuebtn savebtn disclaimerBtn"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        )}
                                    </Tab>
                                    <Tab eventKey="profile" title="HTML Editor">
                                        {!(fileUploadData || getProductDataGroup?.content?.spanish?.file?.url || getProductDataGroup?.content?.english?.file?.url) ? (
                                            <>
                                                <EditorProvider>
                                                    <Editor value={plainTextSpanish} onChange={onChangeSpanish}>
                                                        <Toolbar>
                                                            <BtnBold />
                                                            <BtnItalic />
                                                            <BtnUnderline />
                                                            <HtmlButton />
                                                            <BtnBulletList />
                                                            <BtnNumberedList />
                                                            <BtnStrikeThrough />
                                                            <BtnAlignCenter />
                                                            <BtnAlignLeft />
                                                            <BtnAlignRight />
                                                            <BtnSuperscript />
                                                            <BtnSubscript />
                                                            <BtnImageUpload />
                                                        </Toolbar>
                                                    </Editor>
                                                </EditorProvider>
                                                <div className="mt-3 d-flex justify-content-between textgrey  w-100">
                                                    <div className="fs-16">Note: Please don't add HTML tags to your text.</div>
                                                </div>
                                                {convert(plainTextSpanish).trim() !== "" && (
                                                    <div className="text-end">
                                                        <button
                                                            type="submit"
                                                            className=" sbg border-0 fw-18 gbo continuebtn savebtn disclaimerBtn"
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="removehtml">
                                                Please Remove File in uploaded file
                                            </div>
                                        )}
                                    </Tab>
                                </Tabs>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
            {addProductFeatureModal && (
                <BrandChangeModal addProductFeatureModal={addProductFeatureModal} handleCancel={handleCancel} handleModalYes={handleModalYes} deleteUploadedFile={deleteUploadedFile} />
            )}
        </>
    )
}

export default GroupDragger
