import React, { useContext, useState } from "react";
import { Field, Form, Formik } from "formik";
import eye from "../../assets/eye.svg";
import deleteicon from "../../assets/deleteicon.svg";
import uploadimg from "../../assets/uploadicon.svg";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { fileUploadAction } from "../../redux/action/fileUpload";
import Dragger from "antd/es/upload/Dragger";
import { BrandChangeModal } from "../BrandChangeModal/BrandChangeModal";
import { useCommonMessage } from "../../common/CommonMessage";
import axios from "axios";
import { API_URL } from "../../services/http";
import { ThemeContext } from "../../common/themeContext";
import Loader from "../Loder/loader";
import {
  handleDeleteAllStateGroupWise,
  handleSubmitGroupCoiAllState,
} from "./CoiAllStateCommonPayload";
import { useLocation } from "react-router-dom";

const CoiAllStateGroup = ({
  fileUploadDataSelector,
  selectedCheckbox,
  manageSwitch,
  setManageSwitch,
  getGroupSpanishFileUpload,
  getGroupProductFeatureContent,
  getGroupEnglishFileUpload,
}) => {
  const [addProductFeatureModal, setAddProductFeatureModal] = useState(false);
  const [deleteUploadedFile, setDeleteUploadedFile] = useState("");
  const [fileUploaded, setFileUploaded] = useState("");
  const [spanishFileUpload, setSpanishFileUpload] = useState("");
  const [handleSwitchToast, setHandleSwitchToast] = useState(false);
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();

  console.log(state, "statestatestatestatestatestate");

  const getAdminLoginId = localStorage.getItem("adminId");
  const { brandId } = useContext(ThemeContext);

  const dispatch = useDispatch();
  const messageApi = useCommonMessage();

  const handleCancel = () => {
    setAddProductFeatureModal(false);
  };

  const handleModalYes = () => {
    handleDeleteAllStateGroupWise({
      manageSwitch,
      getGroupProductFeatureContent,
      getGroupSpanishFileUpload,
      selectedCheckbox,
      setAddProductFeatureModal,
      setDeleteUploadedFile,
      setFileUploaded,
      dispatch,
      getGroupEnglishFileUpload,
      setSpanishFileUpload,
    });
  };

  const props = {
    name: "file",
    multiple: false,
    accept: ".pdf",
    showUploadList: false,
    async beforeUpload(file) {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        setFileUploaded("");
        message.error("You can only upload PDF files!");
        return false;
      }
      const isSizeAccepted = file.size / 1024 / 1024 <= 2;
      if (!isSizeAccepted) {
        message.error("File size exceeds 2MB limit!");
        setFileUploaded("");
        return false;
      }
      if (isPDF && isSizeAccepted) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", getAdminLoginId);
        formData.append("brandId", brandId);
        console.log(state,"state")
        formData.append(
          "path",
          `${
            "group/" +
            state?.getGroupProductFeatureSelector?.data?.data?.groupCode +
            "/product/" +
            getGroupProductFeatureContent?.data?.data?.productCode +
            "/" +
            state?.fromGroupData?.featureName +"/" + selectedCheckbox + "/" +(manageSwitch===false?"English":"Spanish")
          }`
        );
        setHandleSwitchToast(true);
        setLoading(true); 
        axios
          .post(API_URL + "/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            dispatch(fileUploadAction.fileUploadInfoSuccess(response.data));
            if (manageSwitch === false) {
              setFileUploaded(file?.name);
            } else {
              setSpanishFileUpload(file?.name);
            }
          })
          .catch((error) => {
            dispatch(fileUploadAction.fileUploadInfoFailure(error));
          })
          .finally(() => {
            setLoading(false);
          });
      }
      return false;
    },
  };

  const deleteFile = () => {
    setAddProductFeatureModal(true);
    setDeleteUploadedFile("DeleteFile");
  };

  // Handle Submit
  const handleFormSubmit = (values) => {
    handleSubmitGroupCoiAllState({
      manageSwitch,
      getGroupProductFeatureContent,
      selectedCheckbox,
      fileUploadDataSelector,
      fileUploaded,
      getGroupSpanishFileUpload,
      dispatch,
      getGroupEnglishFileUpload,
      spanishFileUpload,
    });
  };

  // Language Handler
  const handleEnglishSwitch = () => {
    if (handleSwitchToast && fileUploadDataSelector?.data?.data) {
      messageApi.open({
        type: "warning",
        content: "Please Save File First!!",
      });
      setManageSwitch(true);
    } else {
      setManageSwitch(false);
    }
  };

  const handleSpanishSwitch = () => {
    if (handleSwitchToast && fileUploadDataSelector?.data?.data) {
      messageApi.open({
        type: "warning",
        content: "Please Save File First!!",
      });
      setManageSwitch(false);
    } else {
      setManageSwitch(true);
    }
  };

  return (
    <>
      {loading === true && <Loader loaderTransForm="loaderTransForm" />}
      <Formik
        enableReinitialize
        initialValues={{
          allStateFile: getGroupEnglishFileUpload?.url || "",
          allStateSpanishFile: getGroupSpanishFileUpload?.url || "",
        }}
        onSubmit={(values, formikBag) => {
          handleFormSubmit(values, formikBag);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            {manageSwitch === false ? (
              <>
                <div className="switches-container mb-30">
                  <div className="mempro" onClick={handleEnglishSwitch}>
                    English
                  </div>
                  <div className="mempro" onClick={handleSpanishSwitch}>
                    Spanish
                  </div>
                  <div
                    className={
                      !manageSwitch
                        ? "switch-wrapper"
                        : "switch-wrapper switchside"
                    }
                  >
                    <div className="switch">
                      {!manageSwitch ? <div>English</div> : <div>Spanish</div>}
                    </div>
                  </div>
                </div>
                <div className="btnPosition">
                  <div className="all-state">
                    <div className="sectionpadding bg-white mb-30">
                      <p className="mb-3 heedprodct">Add Certificate </p>
                      <hr />

                      {!(fileUploaded || getGroupEnglishFileUpload?.url) ? (
                        <Field name="allStatefile">
                          {({ field }) => (
                            <Dragger className="allstate" {...props}>
                              <p className="ant-upload-drag-icon">
                                <img src={uploadimg} alt="" />
                              </p>
                              <p className="ant-upload-text">
                                <u>Click here</u> or drag & drop to upload .PDF
                                file
                              </p>
                            </Dragger>
                          )}
                        </Field>
                      ) : (
                        <div className="ant-upload-list ant-upload-list-text ">
                          <span className="ant-upload-list-item-container">
                            <div className="d-flex gap-2 align-items-center file-icon">
                              <img src={uploadimg} alt="" />
                              {fileUploaded ||
                                getGroupEnglishFileUpload?.fileName}
                            </div>
                            <div className="d-flex gap-2">
                              <a
                                href={
                                  fileUploadDataSelector?.data?.data
                                    ?.signedUrl ||
                                  getGroupEnglishFileUpload?.url
                                }
                                target="_blank"
                              >
                                <img src={eye} alt="" />
                              </a>
                              <div
                                onClick={deleteFile}
                                className="cursorPointer"
                              >
                                <img src={deleteicon} alt="" />
                              </div>
                            </div>
                          </span>
                        </div>
                      )}
                    </div>
                    {fileUploadDataSelector?.data !== null && (
                      <div className="text-end">
                        <button
                          type="submit"
                          className=" sbg border-0 fw-18 gbo continuebtn savebtn disclaimerBtn"
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="switches-container mb-30">
                  <div className="mempro" onClick={handleEnglishSwitch}>
                    English
                  </div>
                  <div className="mempro" onClick={handleSpanishSwitch}>
                    Spanish
                  </div>
                  <div
                    className={
                      !manageSwitch
                        ? "switch-wrapper"
                        : "switch-wrapper switchside"
                    }
                  >
                    <div className="switch">
                      {!manageSwitch ? <div>English</div> : <div>Spanish</div>}
                    </div>
                  </div>
                </div>
                <div className="btnPosition">
                  <div className="all-state">
                    <div className="sectionpadding bg-white mb-30">
                      <p className="mb-3 heedprodct">Add Certificate </p>
                      <hr />
                      {!(
                        spanishFileUpload || getGroupSpanishFileUpload?.url
                      ) ? (
                        <Field name="allStateSpanishFile">
                          {({ field }) => (
                            <Dragger className="allstate" {...props}>
                              <p className="ant-upload-drag-icon">
                                <img src={uploadimg} alt="" />
                              </p>
                              <p className="ant-upload-text">
                                <u>Click here</u> or drag & drop to upload .PDF
                                file spanish
                              </p>
                            </Dragger>
                          )}
                        </Field>
                      ) : (
                        <div className="ant-upload-list ant-upload-list-text ">
                          <span className="ant-upload-list-item-container">
                            <div className="d-flex gap-2 align-items-center file-icon">
                              <img src={uploadimg} alt="" />
                              {spanishFileUpload ||
                                getGroupSpanishFileUpload?.fileName}
                            </div>
                            <div className="d-flex gap-2">
                              <a
                                href={
                                  fileUploadDataSelector?.data?.data
                                    ?.signedUrl ||
                                  getGroupSpanishFileUpload?.url
                                }
                                target="_blank"
                              >
                                <img src={eye} alt="" />
                              </a>
                              <div
                                onClick={deleteFile}
                                className="cursorPointer"
                              >
                                <img src={deleteicon} alt="" />
                              </div>
                            </div>
                          </span>
                        </div>
                      )}
                    </div>
                    {fileUploadDataSelector?.data !== null && (
                      <div className="text-end">
                        <button
                          type="submit"
                          className=" sbg border-0 fw-18 gbo continuebtn savebtn disclaimerBtn"
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
      {addProductFeatureModal && (
        <BrandChangeModal
          addProductFeatureModal={addProductFeatureModal}
          handleCancel={handleCancel}
          handleModalYes={handleModalYes}
          deleteUploadedFile={deleteUploadedFile}
        />
      )}
    </>
  );
};

export default CoiAllStateGroup;
