import * as Yup from 'yup';
import { urlRegex } from '../utils/commonUtils';

// Login Validation
export const validationSchemaLogin = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Please enter email address"),
  password: Yup.string().required("Please enter password"),
});


// COI Validations
export const validationSchema = Yup.object().shape({
    englishCertificate: Yup.array().of(
      Yup.object().shape({
        selectedStates: Yup.array()
          .of(Yup.string().required('State selection is required'))
          .min(1, 'At least one state must be selected'),
        file: Yup.mixed(),
      })
    ),
  });
  
 export const validationSchemaSpanish = Yup.object().shape({
    spanishCertificate: Yup.array().of(
      Yup.object().shape({
        selectedStates: Yup.array()
          .of(Yup.string().required('State selection is required'))
          .min(1, 'At least one state must be selected'),
        file: Yup.mixed(),
      })
    ),
  });

// Products Validation
export const validationSchemaProducts = Yup.object().shape({
  product_name: Yup.string().required("Please enter product name"),
  product_code: Yup.string().required("Please enter product code"),
});

// Click to schedule care validation
export const validationSchemaScheduleCare = Yup.object().shape({
  contact_number: Yup.string()
    // .matches(/^\d+$/, "Contact number must contain only digits")
    // .min(7, "Contact number must be at least 7 digits")
    // .max(11, "Contact number cannot exceed 11 digits")
    .required("Please enter contact number")
});

// Faq Validation 
export const validationSchemaFaq = Yup.object({
  questions: Yup.array().of(
    Yup.object({
      question: Yup.string().required("Please enter question"),
      answer: Yup.string().required("Please enter answer"),
    })
  ),
});

export const validationSchemaSpanishFaq = Yup.object({
  questionSpanish: Yup.array().of(
    Yup.object({
      question: Yup.string().required("Please enter question"),
      answer: Yup.string().required("Please enter answer"),
    })
  )
});

// Add quiz validation
export const validationSchemaAddQuiz = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),

  sets: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required(
          'Title is required'
        ),
        description: Yup.string().required('Description is required'),
      })
    ),
  questions: Yup.array()
    .of(
      Yup.object().shape({
        options: Yup.array()
          .of(Yup.string().required('Options are required'))
          .test(
            'min-options',
            'Options are required',
            function (value) {
              const { numberOfChoice } = this.parent;
              return (
                value &&
                value.length >=
                (numberOfChoice ? Number(numberOfChoice) : 2)
              );
            }
          ),
        selectedQuestionType: Yup.string().required(
          'Question type is required'
        ),
        questionText: Yup.string().required('Question text is required'),
      })
    )
});


// Edit Quiz form
export const validationSchemaEditQuiz = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),

  sets: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required(
          'Title is required'
        ),
        description: Yup.string().required('Description is required'),
      })
    ),
  questions: Yup.array()
    .of(
      Yup.object().shape({
        selectedQuestionType: Yup.string().required(
          'Question type is required'
        ),
        options: Yup.array()
          .of(Yup.string().required('Options are required'))
          .test(
            'min-options',
            'Options are required',
            function (value) {
              const { numberOfChoice } = this.parent;
              return (
                value &&
                value.length >=
                (numberOfChoice ? Number(numberOfChoice) : 2)
              );
            }
          ),
        questionText: Yup.string().required('Question text is required'),
      })
    )
});

// Add Claim Form validation schema
export const validationSchemaAddClaimForm = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  questions: Yup.array()
    .of(
      Yup.lazy((question) => {
        if (question.selectedQuestionType === 'textField') {
          return Yup.object().shape({
            selectedQuestionType: Yup.string().required(
              'Question type is required'
            ),
            questionText: Yup.string().required('Question text is required'),
          });
        }
        return Yup.object().shape({
          options: Yup.array()
            .of(Yup.string().required('Options are required'))
            .test('min-options', 'Options are required', function (value) {
              const { numberOfChoice } = this.parent;
              return (
                value &&
                value.length >= (numberOfChoice ? Number(numberOfChoice) : 2)
              );
            }),
          selectedQuestionType: Yup.string().required(
            'Question type is required'
          ),
          questionText: Yup.string().required('Question text is required'),
        });
      })
    )
    .min(1, 'At least one question is required'),
});

// Update Claim validation schema

export const validationSchemaUpdateClaim = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  questions: Yup.array()
    .of(
      Yup.lazy((question) => {
        if (question.selectedQuestionType === 'textField') {
          return Yup.object().shape({
            selectedQuestionType: Yup.string().required('Question type is required'),
            questionText: Yup.string().required('Question text is required'),
          });
        }
        return Yup.object().shape({
          options: Yup.array()
            .of(Yup.string().required('Options are required'))
            .test(
              'min-options',
              'Options are required',
              function (value) {
                const { numberOfChoice } = this.parent;
                return (
                  value &&
                  value.length >=
                  (numberOfChoice ? Number(numberOfChoice) : 2)
                );
              }
            ),
          selectedQuestionType: Yup.string().required('Question type is required'),
          questionText: Yup.string().required('Question text is required'),
        });
      })
    )
    .min(1, 'At least one question is required'),
});

// Redirection URL
export const validationSchemaURLEnglish = Yup.object().shape({
  redirection_URLEnglish: Yup.string().required("Please enter redirection url").matches(urlRegex, "Please enter a valid URL"),
});

export const validationSchemaURLSpanish = Yup.object().shape({
  redirection_URLSpanish: Yup.string().required("Please enter redirection url").matches(urlRegex, "Please enter a valid URL"),
});

// Create Notification
export const createNotificationSchema = {
  date: Yup.string().required('date is required'),
  time: Yup.string().required('time is required'),
};

export const smsSchema = {
  description: Yup.string().required('description is required'),
};
export const emailSchema = {
  subject: Yup.string().required('subject is required'),
  body: Yup.string().required('body is required'),
};
export const pushDescriptionSchema = {
  title: Yup.string().required('title is required'),
  pushDescription: Yup.string().required('pushDescription is required'),
};


// Add claim functionality

export const validationSchemaAddClaim = Yup.object().shape({
  claimName: Yup.string().required("Please enter claim name"),
  claimType: Yup.string().required("Select a claim type"),
});

export const validationSchemaSpanishAddClaim = Yup.object().shape({
  claimNameSpanish: Yup.string().required("Please enter claim name"),
  claimTypeSpanish: Yup.string().required("Select a claim type"),
});



  
