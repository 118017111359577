import { createSlice } from "@reduxjs/toolkit";
import { resportsAPI } from "../../services/reports";

const data = {
    isLoading: false,
    error: "",
    message: "",
    data: null,
};

const reportsSlice = createSlice({
    name: "reportsList",
    initialState: data,
    reducers: {
        reportsSliceInfo(state) {
            state.isLoading = true;
        },
        reportsSliceSuccess(state, action) {
            state.isLoading = false;
            state.data = action.payload;
            state.message = "";
        },
        reportsSliceFailure(state, action) {
            state.isLoading = false;
            state.message = action.payload;
            state.data = null;
        },
        reportsSliceReset(state) {
            state.isLoading = false;
            state.message = "";
            state.data = null;
            state.error = "";
        },
    },
});

export const reportsListHandler = (data) => async (dispatch) => {
    try {
        dispatch(reportsSliceAction.reportsSliceInfo());
        const response = await resportsAPI(data);
        dispatch(reportsSliceAction.reportsSliceSuccess(response));
    } catch (e) {
        dispatch(reportsSliceAction.reportsSliceFailure(e));
    }
};
export default reportsSlice.reducer;
export const reportsSliceAction = reportsSlice.actions;
